<template>
  <b-form-group
    label="Model Yılı"
    label-for="myear"
  >
    <validation-provider
      #default="{ errors }"
      name="Model Yılı"
      rules="required"
    >
      <cleave
        id="myear"
        v-model="lineData[dataKey].myear"
        class="form-control"
        :raw="false"
        :options="options.myear"
        placeholder="Model Yılı"
      />
      <small class="text-danger">{{ errors[0] }}</small>

    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, localize } from 'vee-validate'

export default {
  name: 'ModelYear',
  components: {
    ValidationProvider,
    BFormGroup,
    Cleave,
  },
  props: {
    dataKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      options: {
        myear: {
          blocks: [4],
          numericOnly: true,
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/getOffer']
    },
    lineData() {
      const lineData = this.$store.getters['offers/getOffer']
      return lineData.lines
    },
  },
  created() {
    localize('tr')
  },
}
</script>

<style scoped></style>
