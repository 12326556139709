<template>
  <b-form-group
    label="Marka"
    label-for="brand"
  >
    <validation-provider
      #default="{ errors }"
      name="Marka"
      rules="required"
    >
      <v-select
        id="brand"
        v-model="lineData[dataKey].id_com_brand"
        label="title"
        :reduce="brand => brand.id"
        :options="brands"
        placeholder="Marka"
        @input="getModels"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'BrandCard',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  props: {
    dataKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      locale: 'tr',
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/getOffer']
    },
    lineData() {
      const lineData = this.$store.getters['offers/getOffer']
      return lineData.lines
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
  },
  created() {
    localize(this.locale)
    this.getBrands()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
    getModels() {
      if (this.lineData[this.dataKey].id_com_brand) {
        this.lineData[this.dataKey].id_com_model = null
        this.lineData[this.dataKey].exterior_color = null
        this.lineData[this.dataKey].priceCar = null
        this.lineData[this.dataKey].car = null
        this.lineData[this.dataKey].image = null
        this.$store.dispatch('models/modelsList', {
          select: ['com_model.id AS id', 'com_model.title AS title'],
          where: {
            'com_model.id_com_brand': this.lineData[this.dataKey].id_com_brand,
          },
        })
      }
    },
  },
}
</script>

<style scoped>
</style>
