<template>
  <b-form-group
    label="Araç Teklif Fiyatı"
    label-for="offer_price"
  >
    <validation-provider
      #default="{ errors }"
      name="Araç Teklif Fiyatı"
      rules="required"
    >
      <b-input-group append="TL">
        <cleave
          id="listed_price"
          v-model="lineData[dataKey].offer_price"
          class="form-control"
          :raw="true"
          :options="options.price"
          placeholder="Araç Teklif Fiyatı"
        />
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BFormGroup,
  BInputGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'OfferPrice',
  components: {
    BFormGroup,
    BInputGroup,
    Cleave,
    ValidationProvider,
  },
  props: {
    dataKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/getOffer']
    },
    lineData() {
      const lineData = this.$store.getters['offers/getOffer']
      return lineData.lines
    },
  },
  created() {
    localize('tr')
  },
}
</script>
