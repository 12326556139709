<template>
  <div>
    <b-card title="Araç Bilgisi">
      <b-row>
        <b-col
          v-if="lineData[dataKey].exterior_color"
          cols="12"
          class="text-center"
        >
          <b-img
            v-if="lineData[dataKey].image"
            width="250px"
            :src="'https://gonyeotomotiv.com.tr/media/uploads/colors/' + lineData[dataKey].image"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <brand-card :data-key="dataKey" />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <model-card :data-key="dataKey" />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <model-year :data-key="dataKey" />
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <price-list-card :data-key="dataKey" />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <exterior-color-card :data-key="dataKey" />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <interior-color-card :data-key="dataKey" />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <listed-price :data-key="dataKey" />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <offer-price :data-key="dataKey" />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <price-text :data-key="dataKey" />
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <h4>Ek Fiyat Bilgileri</h4>
        </b-col>
        <b-col
          v-if="lineData[dataKey].price_items.length > 0"
          cols="12"
          md="12"
        >
          <div
            v-for="(item,key) in lineData[dataKey].price_items"
            :key="key"
          >
            <offer-price-item
              :data-key="key"
              :line-key="dataKey"
              :remove-data="removeItem"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <b-button
            variant="primary"
            size="sm"
            @click="addPriceItem"
          >
            Ek Fiyat Satırı Ekle
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BImg, BButton,
} from 'bootstrap-vue'
import BrandCard from '@/views/Offers/elements/lines/Brand.vue'
import ModelCard from '@/views/Offers/elements/lines/Model.vue'
import ModelYear from '@/views/Offers/elements/lines/myear.vue'
import InteriorColorCard from '@/views/Offers/elements/lines/interiorColor.vue'
import ListedPrice from '@/views/Offers/elements/lines/listedPrice.vue'
import OfferPrice from '@/views/Offers/elements/lines/offerPrice.vue'
import PriceText from '@/views/Offers/elements/lines/priceText.vue'
import ExteriorColorCard from '@/views/Offers/elements/lines/exteriorColors.vue'
import PriceListCard from '@/views/Offers/elements/lines/priceList.vue'
import OfferPriceItem from '@/views/Offers/Components/LinePriceITemForm.vue'

export default {
  name: 'OfferProduct',
  components: {
    OfferPriceItem,
    PriceListCard,
    ExteriorColorCard,
    PriceText,
    OfferPrice,
    ListedPrice,
    InteriorColorCard,
    ModelYear,
    ModelCard,
    BrandCard,
    BCard,
    BRow,
    BCol,
    BImg,
    BButton,
  },
  props: {
    dataKey: {
      type: Number,
      required: true,
    },
    removeItem: {
      type: Function,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/getOffer']
    },
    lineData() {
      const LineData = this.$store.getters['offers/getOffer']
      return LineData.lines
    },
  },
  methods: {
    getData() {
      this.$store.dispatch('offers/offerView', this.$route.params.id)
    },
    addPriceItem() {
      this.lineData[this.dataKey].price_items.push({
        title: null,
        value: null,
        id_offer_line: this.lineData[this.dataKey].id,
      })
    },
  },
}
</script>
