<template>
  <div>
    <b-row class="d-flex align-items-center">
      <b-col>
        <b-form-group
          label="Açıklama"
          label-for="title"
        >
          <validation-provider
            #default="{ errors }"
            name="Açıklama"
            rules="required"
          >
            <b-form-input
              id="title"
              v-model="lineData[lineKey].price_items[dataKey].title"
              placeholder="Açıklama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Tutar"
          label-for="value"
        >
          <validation-provider
            #default="{ errors }"
            name="Tutar"
            rules="required"
          >
            <b-form-input
              id="value"
              v-model="lineData[lineKey].price_items[dataKey].value"
              placeholder="Tutar"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="auto">
        <b-button
          variant="flat-danger"
          class="btn-icon"
          pill
          @click="removeData(dataKey,lineKey,lineData[lineKey].price_items[dataKey].id)"
        >
          <FeatherIcon icon="TrashIcon" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'

export default {
  name: 'OfferPriceItem',
  components: {
    ValidationProvider,
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
  },
  props: {
    removeData: {
      type: Function,
      required: true,
    },
    dataKey: {
      type: Number,
      required: true,
    },
    lineKey: {
      type: Number,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/getOffer']
    },
    lineData() {
      const lineData = this.$store.getters['offers/getOffer']
      return lineData.lines
    },
  },
  created() {
    localize('tr')
  },
}
</script>
