<template>
  <div>
    <b-card title="Teklif Şartı">
      <b-row>
        <b-col>
          <offer-terms />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import OfferTerms from '@/views/Offers/elements/termCard.vue'

export default {
  name: 'TermCard',
  components: {
    OfferTerms,
    BRow,
    BCol,
    BCard,
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/getOffer']
    },
  },
}
</script>
