<template>
  <b-form-group
    label="Dış Renk"
    label-for="exterior_color"
  >
    <validation-provider
      #default="{ errors }"
      name="Dış Renk"
      rules="required"
    >
      <v-select
        id="exterior_color"
        v-model="lineData[dataKey].exterior_color"
        label="title"
        :reduce="color => color.id"
        :options="colors"
        placeholder="Dış Renk"
        @input="findImage"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'ExteriorColorCard',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  props: {
    dataKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      locale: 'tr',
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/getOffer']
    },
    lineData() {
      const lineData = this.$store.getters['offers/getOffer']
      return lineData.lines
    },
    colors() {
      return this.$store.getters['offers/getColors']
    },
  },
  created() {
    localize(this.locale)
  },
  methods: {
    findImage() {
      const image = this.colors.find(({ id }) => id === this.lineData[this.dataKey].exterior_color)
      if (image) {
        this.lineData[this.dataKey].image = image.image
        this.lineData[this.dataKey].exterior_color = image.title
      }
    },
  },
}
</script>

<style scoped>
</style>
