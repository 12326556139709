<template>
  <div>
    <b-card title="Teklif Bilgileri">
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <date-picker-card />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <validation-date-picker-card />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <offer-number />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <title-card />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <location-card />
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <content-card />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import TitleCard from '@/views/Offers/elements/titleCard.vue'
import OfferNumber from '@/views/Offers/elements/offerNumber.vue'
import DatePickerCard from '@/views/Offers/elements/datePicker.vue'
import ValidationDatePickerCard from '@/views/Offers/elements/vDatePicker.vue'
import ContentCard from '@/views/Offers/elements/content.vue'
import LocationCard from '@/views/Offers/elements/Location.vue'

export default {
  name: 'OfferForm',
  components: {
    LocationCard,
    ContentCard,
    ValidationDatePickerCard,
    DatePickerCard,
    OfferNumber,
    TitleCard,
    BRow,
    BCol,
    BCard,
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/getOffer']
    },
  },
}
</script>
