<template>
  <b-form-group
    label="Model"
    label-for="model"
  >
    <validation-provider
      #default="{ errors }"
      name="Model"
      rules="required"
    >
      <v-select
        id="model"
        v-model="lineData[dataKey].id_com_model"
        label="title"
        :reduce="model => model.id"
        :options="models"
        placeholder="Model"
        @input="getColors"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'ModelCard',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  props: {
    dataKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      locale: 'tr',
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/getOffer']
    },
    lineData() {
      const lineData = this.$store.getters['offers/getOffer']
      return lineData.lines
    },
    models() {
      return this.$store.getters['models/getModels']
    },
  },
  created() {
    localize(this.locale)
  },
  methods: {
    getColors() {
      if (this.lineData[this.dataKey].id_com_model) {
        this.lineData[this.dataKey].id_com_color = null
        this.lineData[this.dataKey].priceCar = null
        this.lineData[this.dataKey].car = null
        this.lineData[this.dataKey].image = null
        this.$store.dispatch('offers/getModelInfo', this.lineData[this.dataKey].id_com_model)
      }
    },
  },
}
</script>

<style scoped>
</style>
