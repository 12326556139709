<template>
  <div>
    <b-form-group
      label="Araç Seçimi"
      label-for="priceCar"
    >
      <v-select
        id="model"
        v-model="lineData[dataKey].car"
        label="title"
        :reduce="model => model.id"
        :options="priceList"
        placeholder="Araç Seçiniz"
        @input="setPrices"
      >
        <template v-slot:option="option">
          {{ option.title }}
          <div>
            <small class="text-warning">{{ option.myear }}</small>
            <div>
              <small class="text-danger">{{ option.engine }}</small>
            </div>
            <div>
              <small class="text-danger">{{ option.hardware }}</small>
            </div>
            <div>
              <small class="text-danger">{{ option.list_price |toCurrency }} TL</small>
            </div>
          </div>
        </template>
        <template v-slot:selected-option="option">
          {{ option.title }}
          <div>
            <small class="text-warning"> {{ option.myear }}</small>
          </div>
        </template>
      </v-select>
    </b-form-group>
    <b-form-group
      label="Araç"
      label-for="carTitle"
    >
      <b-form-input
        v-model="lineData[dataKey].priceCar"
        placeholder="Araç"
      />
    </b-form-group>
  </div>
</template>

<script>
import { localize } from 'vee-validate'
import vSelect from 'vue-select'

import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'PriceListCard',
  components: {
    BFormInput,
    BFormGroup,
    vSelect,
  },
  props: {
    dataKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/getOffer']
    },
    priceList() {
      return this.$store.getters['offers/getPrices']
    },
    models() {
      return this.$store.getters['models/getModels']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    lineData() {
      const lineData = this.$store.getters['offers/getOffer']
      return lineData.lines
    },
  },
  created() {
    localize(this.locale)
  },
  methods: {
    setPrices() {
      // model marka bilgisi getiriyor
      const brand = this.brands.find(({ id }) => id === this.lineData[this.dataKey].id_com_brand)
      const model = this.models.find(({ id }) => id === this.lineData[this.dataKey].id_com_model)
      const price = this.priceList.find(({ id }) => id === this.lineData[this.dataKey].car)
      // araç tanımını dolduruyor
      this.lineData[this.dataKey].priceCar = `${brand.title} ${model.title} ${price.title}`
      this.lineData[this.dataKey].myear = price.myear
      // fiyatı belirliyor
      if (price.deal_price > 0 && price.deal_price !== price.list_price) {
        this.lineData[this.dataKey].listed_price = price.deal_price
      } else {
        this.lineData[this.dataKey].listed_price = price.list_price
      }
    },
  },
}
</script>

<style scoped></style>
