<template>
  <b-form-group
    label="Lokasyon"
    label-for="id_com_location"
  >
    <validation-provider
      #default="{ errors }"
      name="Lokasyon"
      rules="required"
    >
      <v-select
        id="id_com_location"
        v-model="dataItem.id_com_location"
        label="title"
        :reduce="location => location.id"
        :options="locations"
        placeholder="Lokasyon"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'LocationCard',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/getOffer']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
  },
  created() {
    localize(this.locale)
    this.getLocations()
  },
  methods: {
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        select: ['com_location.id AS id', 'com_location.title AS title'],
      })
    },
  },
}
</script>

<style scoped>
</style>
